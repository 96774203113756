@import 'styles/colors.scss';
.Unauthorised {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    .UnauthorisedMessage {
        width: 70%;
        p {
            margin-top: 1.25em !important;
        }
    }
}