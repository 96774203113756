.HubButton:hover {
    border: 2px solid #A4D7EF;
    border-radius: 10px;
    background: #E3F2FA;

    h1,
    h4 {
        color: #0E426B;
    }
}

.HubButton {
    border: 2px solid transparent;
    min-width: 260px;
    max-width: 322px;
    min-height: 320px;
    margin: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .imgContainer {
        height: 140px;
        width: 140px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    h1,
    h4 {
        font-weight: 400;
        margin: 5px;
        color: #666666;
        text-align: center;
    }

    h1 {
        font-size: 30px;
    }

    h4 {
        font-size: 16px;
    }
}