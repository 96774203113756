.Navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 30px 100px 10px 0;
    height: 60px;
    position: fixed;
    top: 0;

    .userDropDown {
        font-size: 16px;
    }

    .icon {
        text-decoration: none;
        color: #000000;
        font-size: 2.8em;
        height: 20px;
        padding-right: 10px;
    }

    button:hover {
        background: transparent;
    }
}