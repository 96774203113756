// RPM UC Brand colors
// -----------------------------------------------------------------------~ //
$uc-scroll-color: #435d77;
$uc-scroll-main-bg-color: #008fb7;
$uc-action-color: #CBD1D6;
$uc-action-selected-color: #42719A;
$uc-active-button-color: #1B75BC;
$uc-text-white-color: white;
$uc-box-shadow-aqb-color: #888;
$uc-car-image-bg-color: #231f20;
$uc-black-color: black;
$uc-grey-btn-color: #8997A5;
$uc-blue-color: #40A4C8;
$uc-almost-black-color: #1b1c1d;
$uc-light-grey: #e8e8e8;

// RPM Brand colors
// -----------------------------------------------------------------------~ //
$rpm-blue: #1b75bc;
$light-rpm-blue: #00416b;
$dark-rpm-blue: #40b1e5;

$almost-white: #f8f8f8;
$grey: #9aa8ac;
$dark-grey: #4d5858;
$tire-black: #1c1f2a;
$dark-tire-black: #131e29;

$slate-grey: #8A98A7;
$light-slate: #BFCBD8;
$alt-slate: #B7C1CB;

$internal-blue: #4F7CAD;
$interactive-blue: #65A9DE;

$wrong-red: #F85E7C;
$right-green: #349B68;