.Snackbar {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    z-index: 100;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    .Message {
        z-index: 100;
        width: max-content;
        color: black;
        padding: 10px 25px !important;
        box-shadow: none !important;
        :last-child {
            padding-right: 30px;
        }
    }
}