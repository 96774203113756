.Loader {
    color: #0E426B !important;
}

.Loader::after {
    border-color: #0E426B transparent transparent !important;
}

.Dimmer {
    background-color: rgba(255, 255, 255, .70) !important;
}

.HubContainer {
    width: 100%;
    padding-top: 85px;
    padding-bottom: 60px;

    .Grid {
        margin: 0;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    }
}