@import 'styles/colors.scss';
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0 !important;
    font-family: 'Roboto', sans-serif;
    background-color: #EAEAEA;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* scrollbar IE */
    scrollbar-face-color: $uc-scroll-color;
    scrollbar-track-color: $uc-box-shadow-aqb-color;
    scrollbar-shadow-color: $uc-scroll-color;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 1px;
    border-radius: 1px;
    background-color: $uc-blue-color !important;
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

::-webkit-scrollbar-track {
    //     border: 1px solid $uc-scroll-color;
    background-color: transparent; //$uc-box-shadow-aqb-color;
}

///
::-moz-scrollbar {
    width: 2px;
    height: 4px;
}